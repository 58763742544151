import React from "react";
import API from '../../contentful/api'
import { Navlink } from '../../contentful/navlinks.types'
import NavbarRootLink from './NavbarRootLink'

export type NavbarProps = {
  data: Navlink[];
  uri?: string[] | string | undefined;
};

export const Navbar = ({ uri, data }: NavbarProps) => {
  const navlinks = data.map((link: Navlink) => {
    return (<NavbarRootLink key={link.id} uri={uri} link={link} />);
  });

  return (
    <nav className="navbar-youplay navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button type="button" className="navbar-toggle collapsed" data-toggle="off-canvas" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a className="navbar-brand" href="/">
            <img src="/assets/images/logo-du.png" alt="" />
          </a>
        </div>
        <div id="navbar" className="navbar-collapse collapse">
          <ul className="nav navbar-nav">
            {navlinks}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;