import React from "react";
import { Navlink } from '../../contentful/navlinks.types'
import generateHref from './generateHref';

// const Image = styled.img`
//   width: 80%;
//   height: 300px;
//   object-fit: cover;
// `;

export type NavbarChildrenProps = {
  children: Navlink[];
  uri?: string[] | string | undefined;
};

export const NavbarChildren = ({ children, uri }: NavbarChildrenProps) => {
  const childElements = children.map((link: Navlink) => {
    let classes = Array.isArray(uri) && uri.join('/').toLowerCase() === link.internalLink ? 'active' : undefined;
    return (
      <li key={link.id} className={classes}>
        <a href={generateHref(link)}>{link.title}</a>
      </li>
    )
  });

  return (
    <div className="dropdown-menu">
      <ul role="menu">
        {childElements}
      </ul>
    </div>
  )
};

export default NavbarChildren;

