import { Navlink } from '../../contentful/navlinks.types'

export const generateHref = (link: Navlink) => {
  if (link.internalLink) {
    return '/' + link.internalLink;
  }

  if (link.externalLink) {
    return link.externalLink;
  }

  return '#';
};

export default generateHref;

