import React from "react";
import { Navlink } from '../../contentful/navlinks.types';
import NavbarChildren from './NavbarChildren';
import generateHref from './generateHref';

export type NavbarRootLinkProps = {
  link: Navlink;
  uri?: string[] | string | undefined;
};

export const NavbarRootLink = ({ link, uri }: NavbarRootLinkProps) => {
  const hasChildren = Array.isArray(link.children) && link.children.length;
  let classes = "dropdown dropdown-hover";
  let children = null;

  if ((Array.isArray(uri) && uri[0].toLowerCase() === link.title.toLowerCase())) {
    classes += " active";
  }

  // <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">
  return (
    <li key={link.id} className={classes}>
      {hasChildren ? (
        <>
          <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">
            {link.title} <span className="caret"></span>
            <span className="label">{link.description}</span>
          </a>
          <NavbarChildren children={link.children} uri={uri} />
        </>
      ) : (
          <a href={generateHref(link)}>
            {link.title}
            <span className="label">{link.description}</span>
          </a>
        )}
    </li>
  )
};

export default NavbarRootLink;

/*
<li className="dropdown dropdown-hover">
              <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">
                About <span className="caret"></span>
                <span className="label">information</span>
              </a>
              <div className="dropdown-menu">
                <ul role="menu">
                  <li>
                    <a href="/about/guild-history">Guild History</a>
                  </li>
                  <li>
                    <a href="/about/guild-leadership">Guild Leadership</a>
                  </li>
                  <li>
                    <a href="/about/raid-requirements">Raid Requirements</a>
                  </li>
                  <li>
                    <a href="/about/application-process">Application Process</a>
                  </li>
                  <li>
                    <a href="/about/contact-us">Contact Us</a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="dropdown dropdown-hover">
              <a href="/sales">
                Sales
                <span className="label">get gear</span>
              </a>
            </li>
            <li className="dropdown dropdown-hover">
              <a href="https://apply.decidedlyuncouth.com/">
                Apply
                <span className="label">join us</span>
              </a>
            </li>
*/